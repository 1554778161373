<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-food</v-icon>
               <h2>¿Cómo prefiere comer su comida?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0" justify="center">
               <v-chip-group
                    v-model="comida"
                    column
                    @change="setComida($event)"
                    class="text-center"
                >
                    <v-chip
                    filter
                    outlined
                    value="baja"
                    >
                        Grasa Baja
                    </v-chip>

                    <v-chip
                    filter
                    outlined
                    value="promedio"
                    >
                        Grasa Promedio
                    </v-chip>

                    <v-chip
                    filter
                    outlined
                    value="alto"
                    >
                        Grasa Alto
                    </v-chip>
               </v-chip-group>
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q4"
                    >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q6"
                    :disabled="!comida"
                    >
                    Continuar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    
    mixins: [validationMixin],

    validations: {
      comida: { required, numeric },
    },

    data() {
        return {
            comida: ''
        }
    },

    mounted() {
        this.comida = this.$store.state.comida
        this.$store.state.progress = 35
        this.goBackIfEmpty()
    },

    computed: {
        comidaErrors () {
            const errors = []
            if (!this.$v.comida.$dirty) return errors
            !this.$v.comida.required && errors.push('Preferencias de comida es requerido.')
            !this.$v.comida.numeric && errors.push('Debe ser un número.')
            return errors
        },
    },

    methods: {
        setComida(value) {
            this.$store.state.comida = value
            this.$v.comida.$touch()
        },
        goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }else if(!this.$store.state.personas) {
                this.$router.push('/personas')
           }else if(!this.$store.state.cereales) {
                this.$router.push('/q1')
           }else if(!this.$store.state.carnes) {
               this.$router.push('/q2')
           }else if(!this.$store.state.lacteos) {
               this.$router.push('/q3')
           }else if(!this.$store.state.huevos) {
               this.$router.push('/q4')
           }
       }
    },
}
</script>

<style>
    .v-slide-group__content{
        justify-content: center;
    }
</style>